import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import LeftArrow from '../../Images/left-arrow.png'
import RightArrow from '../../Images/right-arrow.png'

function PaginationBlock(props) {

    const [totalPages, setTotalPages] = useState(0)
    const [currentBox, setCurrentBox] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const [pageNumbers, setPageNumbers] = useState([1, 2, 3])

    const updatePageNumbers = (currentPage) => {
        setPageNumbers([currentPage - 2, currentPage - 1, currentPage])
    }

    const Next = () => {
        if (totalPages > 1) {
            if (currentPage < totalPages) {
                setCurrentPage(currentPage + 1)
                props.setPage(currentPage + 1)
                
                if (currentPage + 1 > 2) { updatePageNumbers(currentPage + 2) }
                if (currentBox < 3) { setCurrentBox(currentBox + 1)
                    props.setSelectedBox(currentBox + 1) }
                
            }
        }
    }

    const Previous = () => {

        if (currentPage > 0) {
           
            if (currentPage > 0) {
                props.setPage(currentPage - 1)
                setCurrentPage(currentPage - 1)
                setCurrentBox(currentBox - 1)
            }
            if (currentPage  < 4) {
                setCurrentBox(currentBox - 1)
            }
            if (currentPage  > 3) {
                updatePageNumbers(currentPage - 1)
            }
        }
    }

    const boxClicked = (box) => {
        props.setPage(pageNumbers[box] - 1)
        setCurrentPage(pageNumbers[box])
        setCurrentBox(box + 1)
        props.setSelectedBox(box + 1)
    }

    useEffect(() => {
        setTotalPages(props.totalPages)
        setCurrentPage(props.currentPage)

        setCurrentBox(props.selectedBox)
    
        if (props.currentPage > 1) { updatePageNumbers(props.currentPage + 1)
        setCurrentBox(3)}
        if (props.currentPage === 0) {setCurrentBox(1)}
        if (props.currentPage === 1) {setCurrentBox(2)}
        if (props.currentPage === 2) {setCurrentBox(3)}

    }, [props.totalPages, setCurrentBox]);

    return (
        <Container style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Row>
                <Col style={{ padding: '0px' }} onClick={() => Previous()}><button style={{ border: 'none', backgroundColor: 'transparent' }}><div style={{marginTop:'10px',marginBottom:'10px', borderStyle: 'solid', borderWidth: '1px', borderRadius: '5px', borderColor: 'transparent', width: '35px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: "0 0 0 1px rgba(43,45,80,.1),0 2px 5px 0 rgba(43,45,80,.1),0 3px 9px 0 rgba(43,45,80,.08),0 1px 1.5px 0 rgba(0,0,0,.08),0 1px 2px 0 rgba(0,0,0,.08)" }}><img alt="back" src={LeftArrow} style={{ height: '10px' }} /></div></button></Col>
                <Col style={{ padding: '0px' }} onClick={() => boxClicked(0)}><button style={{ border: 'none', backgroundColor: 'transparent' }}><div style={{marginTop:'10px',marginBottom:'10px', borderStyle: 'solid', borderWidth: '1px', borderRadius: '5px', borderColor: 'transparent', width: '35px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: "0 0 0 1px rgba(43,45,80,.1),0 2px 5px 0 rgba(43,45,80,.1),0 3px 9px 0 rgba(43,45,80,.08),0 1px 1.5px 0 rgba(0,0,0,.08),0 1px 2px 0 rgba(0,0,0,.08)", fontFamily: 'Montserrat', fontSize: '16px', fontWeight: '500', color: currentBox === 1 ? 'white' : '#616160', backgroundColor: currentBox === 1 ? '#787788' : 'transparent' }}>{pageNumbers[0]}</div></button></Col>
                {totalPages > 1 && <Col style={{ padding: '0px' }} onClick={() => boxClicked(1)}><button style={{ border: 'none', backgroundColor: 'transparent' }}><div style={{marginTop:'10px',marginBottom:'10px', borderStyle: 'solid', borderWidth: '1px', borderRadius: '5px', borderColor: 'transparent', width: '35px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: "0 0 0 1px rgba(43,45,80,.1),0 2px 5px 0 rgba(43,45,80,.1),0 3px 9px 0 rgba(43,45,80,.08),0 1px 1.5px 0 rgba(0,0,0,.08),0 1px 2px 0 rgba(0,0,0,.08)", fontFamily: 'Montserrat', fontSize: '16px', fontWeight: '500', color: currentBox === 2 ? 'white' : '#616160', backgroundColor: currentBox === 2 ? '#787788' : 'transparent' }}>{pageNumbers[1]}</div></button></Col>}
                {totalPages > 2 && <Col style={{ padding: '0px' }} onClick={() => boxClicked(2)}><button style={{ border: 'none', backgroundColor: 'transparent' }}><div style={{marginTop:'10px',marginBottom:'10px', borderStyle: 'solid', borderWidth: '1px', borderRadius: '5px', borderColor: 'transparent', width: '35px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: "0 0 0 1px rgba(43,45,80,.1),0 2px 5px 0 rgba(43,45,80,.1),0 3px 9px 0 rgba(43,45,80,.08),0 1px 1.5px 0 rgba(0,0,0,.08),0 1px 2px 0 rgba(0,0,0,.08)", fontFamily: 'Montserrat', fontSize: '16px', fontWeight: '500', color: currentBox === 3 ? 'white' : '#616160', backgroundColor: currentBox === 3 ? '#787788' : 'transparent' }}>{pageNumbers[2]}</div></button></Col>}
                <Col style={{ padding: '0px' }} onClick={() => Next()}><button style={{ border: 'none', backgroundColor: 'transparent' }}><div style={{marginTop:'10px',marginBottom:'10px', borderStyle: 'solid', borderWidth: '1px', borderRadius: '5px', borderColor: 'transparent', width: '40px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: "0 0 0 1px rgba(43,45,80,.1),0 2px 5px 0 rgba(43,45,80,.1),0 3px 9px 0 rgba(43,45,80,.08),0 1px 1.5px 0 rgba(0,0,0,.08),0 1px 2px 0 rgba(0,0,0,.08)" }}><img alt="Forward" src={RightArrow} style={{ height: '10px' }} /></div></button></Col>
            </Row>


        </Container>
    )
}
export default PaginationBlock