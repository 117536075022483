import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import FilterBackground from "../../Images/filter-gradient.png";
import ClassesFilter from "../../Components/Filters/ClassesFilter";
import IntensityFilter from "../../Components/Filters/IntensityFilter";
import InstructorFilter from "../../Components/Filters/IntensityFilter";
import DurationsFilter from "../../Components/Filters/DurationsFilter";
import { getAxiosInstance } from "../../axios";
import MobileFilters from "../../Components/MobileFilters/MobileFilters";
import FilterIcon from "../../Images/filtericon.svg";
import "./Filters.css";

function Filters(props) {
  const [filterData, setFilterData] = useState();
  const [selectedClass, setSelectedClass] = useState(0);
  const [selectedDuration, setSelectedDuration] = useState("");
  const [selectedInstructor, setSelectedInstructor] = useState(0);
  const [selectedIntensity, setSelectedIntensity] = useState(0);
  const [showMobileFilter, setShowMobileFilter] = useState(false);
  const [searchText, setSearchText] = useState("");

  const applyFilters = () => {
    var filterArray = new Array();
    filterArray = {
      class: selectedClass,
      duration: selectedDuration,
      intensity: selectedIntensity,
      instructor: selectedInstructor,
    };
    console.log(filterArray);
    localStorage.setItem("filters", JSON.stringify(filterArray));
    props.reload();
    setShowMobileFilter(false);
  };
  const search = (e) => {
    console.log(e.keyCode);

    if (e.keyCode == 13) {
      setSelectedIntensity(0);
      setSelectedClass(0);
      setSelectedDuration("");
      setSelectedInstructor("");
      localStorage.removeItem("filters");
      props.reload(undefined, searchText);
      var filterArray = new Array();
      filterArray = { search: e.target.value };
      console.log(filterArray);
      localStorage.setItem("filters", JSON.stringify(filterArray));
    } else {
      console.log(e.target.value);
      setSearchText(e.target.value);
      var filterArray = new Array();
      filterArray = { search: e.target.value };
      console.log(filterArray);
      localStorage.setItem("filters", JSON.stringify(filterArray));
    }

    if (e.keyCode == 8 && e.target.value === "") {
      clearFilters();
    }
  };

  const clearFilters = () => {
    setSelectedIntensity(0);
    setSelectedClass(0);
    setSelectedDuration("");
    setSelectedInstructor("");
    localStorage.removeItem("filters");
    let event = new Event("reload-vids", { bubbles: true });
    window.dispatchEvent(event);
  };
  useEffect(() => {
    getAxiosInstance()
      .get("/filters.php")
      .then((response) => {
        console.log(response);
        setFilterData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div style={{ width: "100%", zIndex: 7 }}>
      {filterData && (
        <div style={{ zIndex: 7, width: "100%", padding: 0 }}>
          <Container
            className="d-none d-lg-flex"
            fluid
            style={{
              width: "100%",
              zIndex: 7,
              backgroundImage: "url(" + FilterBackground + ")",
              height: "70px",
              backgroundSize: "cover",
            }}
          >
            <Row style={{ display: "flex", width: "100%", zIndex: "99" }}>
              <Col xs="auto" style={{ paddingTop: 15 }}>
                <ClassesFilter
                  value={selectedClass}
                  selected={(e) => (
                    setSelectedClass(e), console.log("class filter: " + e)
                  )}
                  title="Class Type"
                  data={filterData.classes}
                />
              </Col>
              <Col xs="auto" style={{ paddingTop: 15 }}>
                <DurationsFilter
                  value={selectedDuration}
                  selected={(e) => setSelectedDuration(e)}
                  title="Duration"
                  data={filterData.durations}
                />
              </Col>
              <Col xs="auto" style={{ paddingTop: 15 }}>
                <IntensityFilter
                  value={selectedIntensity}
                  selected={(e) => setSelectedIntensity(e)}
                  title="Intensity"
                  data={filterData.intensities}
                />
              </Col>
              <Col xs="auto" style={{ paddingTop: 15, zIndex: "99" }}>
                <InstructorFilter
                  value={selectedInstructor}
                  selected={(e) => setSelectedInstructor(e)}
                  title="Instructor"
                  data={filterData.instructors}
                />
              </Col>
              <Col
                xs="auto"
                style={{ paddingTop: 15 }}
                onClick={() => applyFilters()}
              >
                <Container
                  style={{
                    backgroundColor: "#DCA591",
                    width: "auto",
                    zIndex: 3,
                    borderRadius: "10px",
                    paddingTop: 10,
                    paddingBottom: 10,
                    cursor: "pointer",
                  }}
                >
                  <Row
                    style={{
                      paddingLeft: 15,
                      paddingRight: 15,
                      alignItems: "center",
                      paddingTop: 0,
                      fontFamily: "Montserrat",
                      color: "white",
                      fontSize: "16px",
                      fontWeight: 600,
                    }}
                  >
                    Apply Filters
                  </Row>
                </Container>
              </Col>
              <Col
                xs="auto"
                style={{ paddingTop: 15 }}
                onClick={() => clearFilters()}
              >
                <Container
                  style={{
                    backgroundColor: "transparent",
                    width: "auto",
                    zIndex: 3,
                    borderRadius: "10px",
                    paddingTop: 10,
                    paddingBottom: 5,
                    cursor: "pointer",
                  }}
                >
                  <Row
                    style={{
                      paddingLeft: 15,
                      paddingRight: 15,
                      alignItems: "center",
                      paddingTop: 0,
                      fontFamily: "Montserrat",
                      color: "white",
                      fontSize: "16px",
                      fontWeight: 600,
                    }}
                  >
                    Clear Filters x
                  </Row>
                </Container>
              </Col>
              <Col style={{ paddingTop: 15, flex: 1 }}>
                <input
                  onKeyUp={(e) => search(e)}
                  style={{
                    position: "absolute",
                    right: "0px",
                    backgroundColor: "transparent",
                    borderBlockWidth: "3px",
                    borderColor: "white",
                    borderRadius: "10px",
                    outline: "none",
                    borderStyle: "solid",
                    color: "white",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    fontFamily: "Montserrat",
                  }}
                  id="search"
                  placeholder="Search"
                  type="text"
                />
              </Col>
            </Row>
          </Container>
        </div>
      )}

      <div style={{ width: "100%" }}>
        <Container
          fluid
          className="d-block d-lg-none"
          fluid
          style={{ zIndex: 5, width: "100%" }}
        >
          <Row
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              paddingTop: "20px",
              paddingBottom: "20px",
            }}
          >
            <Col
              xs={5}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Button
                onClick={() => setShowMobileFilter(!showMobileFilter)}
                style={{
                  background:
                    "linear-gradient(90deg, rgba(246,221,187,1) 0%, rgba(220,165,145,1) 25%, rgba(154,126,131,1) 50%, rgba(120,119,136,1) 75%, rgba(99,125,148,1) 100%)",
                  border: "none",
                  width: "100%",
                  height: "50px",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  fontSize: "16px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img style={{ marginRight: "5px" }} src={FilterIcon} />
                Filters
              </Button>
            </Col>
            <Col
              xs={7}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <input
                onKeyUp={(e) => search(e)}
                style={{
                  backgroundColor: "transparent",
                  borderWidth: "2px",
                  borderColor: "#9A7E83",
                  borderRadius: "10px",
                  outline: "none",
                  borderStyle: "solid",
                  color: "#616160",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
                id="search2"
                placeholder="Search"
                type="text"
              />
            </Col>
          </Row>
          {showMobileFilter && (
            <Row>
              <MobileFilters
                apply={() => applyFilters()}
                clear={() => clearFilters()}
                selectedClass={(e) => setSelectedClass(e)}
                selectedIntensity={(e) => setSelectedIntensity(e)}
                selectedDuration={(e) => setSelectedDuration(e)}
                selectedInstructor={(e) => setSelectedInstructor(e)}
                valueClass={selectedClass}
                valueDuration={selectedDuration}
                valueIntensity={selectedIntensity}
                valueInstructor={selectedInstructor}
                data={filterData}
              />
            </Row>
          )}
        </Container>
      </div>
    </div>
  );
}
export default Filters;
