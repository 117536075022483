import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { getAxiosInstance } from "../../axios";
import ExerciseDetail from "../../Components/ExerciseDetail/ExerciseDetail";
import ExerciseDetailMobile from "../../Components/ExerciseDetailMobile/ExerciseDetailMobile";
import "./View.css";
import Heart from "../../Images/heart.png";
import HeartDeselected from "../../Images/heart_deselected.png";
import Watched from "../../Images/watched.png";
import Player from "@vimeo/player";
import Library from "../../Images/grid-2.png";

function View() {
  const [vidData, setVidData] = useState();
  const [isFave, setIsFave] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [hasWatched, setHasWatched] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const id = params.get("id");
    getAxiosInstance()
      .post(
        "/videobyid_v2.php?id=" + id + "&user=" + localStorage.getItem("userID")
      )
      .then((response) => {
        console.log(response);
        setVidData(response.data);

        window.parent.postMessage(
          {
            event_id: "show_disclaimer",
            data: { v1: response.data.disclaimer },
          },
          "*"
        );
        if (response.data.favourite === 1) {
          setIsFave(true);
        } else {
          setIsFave(false);
        }

        if (response.data.watched === "true") {
          setHasWatched(true);
        } else {
          setHasWatched(false);
        }

        var iframe = document.getElementById("vimeo-iframe");
        console.log(iframe);
        var player = new Player(iframe);

        player.on("play", function () {
          console.log("Played the video!!");
          setIsPlaying(true);
          addWatched();
          setHasWatched(true);
        });

        player.on("ended", function (data) {
          setIsPlaying(false);
        });

        player.on("pause", function (data) {
          setIsPlaying(false);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  async function addWatched() {
    const params = new URLSearchParams(window.location.search);
    const id = params.get("id");
    getAxiosInstance()
      .post("/watched.php?uid=" + localStorage.getItem("userID") + "&vid=" + id)
      .then((response) => {
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const addFave = (id, vid) => {
    getAxiosInstance()
      .post("/addfave.php?user=" + id + "&vid=" + vid)
      .then((response) => {
        console.log(response.data);
        if (response.data === 1) {
          setIsFave(true);
        } else {
          setIsFave(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Container id="main-container" fluid>
      <Row id="video-row-view">
        <div className="outer-video-div">
          <div className="video-div">
            {vidData && (
              <div class="embed-responsive embed-responsive-16by9">
                <iframe
                  id="vimeo-iframe"
                  title="Video"
                  class="embed-responsive-item"
                  src={
                    "https://player.vimeo.com" +
                    vidData.uri.replace("videos", "video").toString()
                  }
                  width="100%"
                  height="100%"
                  frameborder="0"
                  allow="fullscreen"
                  allowfullscreen
                ></iframe>
              </div>
            )}
          </div>
          {isPlaying === false && (
            <div className="video-overlay">
              <div className="container-fluid">
                <div
                  className="row icon-row"
                  onClick={() => (window.location.href = "/")}
                >
                  <img className="icon-image" src={Library} /> Go to Library
                </div>
                <div
                  className="row icon-row"
                  onClick={() =>
                    addFave(localStorage.getItem("userID"), vidData.id)
                  }
                >
                  {isFave}
                  {isFave ? (
                    <img className="icon-image" src={Heart} />
                  ) : (
                    <img className="icon-image" src={HeartDeselected} />
                  )}{" "}
                  Add Favourite
                </div>
                {hasWatched && (
                  <div className="row icon-row">
                    <img className="icon-image" src={Watched} /> Watched
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </Row>
      <Row className="d-none d-sm-flex">
        <ExerciseDetail data={vidData} />
      </Row>
      <Row className="d-flex d-sm-none">
        {vidData && <ExerciseDetailMobile data={vidData} />}
      </Row>
    </Container>
  );
}
export default View;
