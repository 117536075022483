import React from 'react'
import Divider from '../../Images/divider.png'
import { Container, Row, Col } from 'react-bootstrap'
import './ExerciseDetailMobile.css'
import YogaIcon from '../../Images/yogaicon.png'
import DurationIcon from '../../Images/durationicon.png'
import IntensityIcon from '../../Images/intensityicon.png'

function ExerciseDetailMobile(props){

    var vidData = props.data

    return(
    <Container id="main-con" fluid>
        <Row id="instructor-image-row"><img src={vidData.instructor_image}/></Row>
        <Row id="instructor-name-row">{vidData && vidData.instructor_name}</Row>
        <Row id="title-row-mobile"><div>{vidData && vidData.title}</div></Row>
        <Row>
            <Container id="levels-container">
                <Row>
                    <Col><Container><Row><img alt="yoga" src={YogaIcon}/>Exercise</Row><Row id="levels-text">{vidData.class_name}</Row></Container></Col>
                    <Col><Container><Row><img alt="duration" src={DurationIcon}/>Duration</Row><Row id="levels-text">{vidData.duration}</Row></Container></Col>
                    <Col><Container><Row><img alt="intensity" src={IntensityIcon}/>Intensity</Row><Row id="levels-text">{vidData.intensity}</Row></Container></Col>
                    </Row>
            </Container>
        </Row>
        <Row id="divider-row"><img alt="divider" src={Divider} /></Row>
        <Row id="about-text">About this Exercise</Row>
        <Row id="about-row">{vidData.description_long}</Row>
        <Row id="line"><hr/></Row>
        <Row id="about-text">Further Information</Row>
        <Row id="about-row">{vidData.requirements}</Row>
    </Container>
    )
}
export default ExerciseDetailMobile