import Categories from "../Components/Category/Categories";
import { Container, Row } from "react-bootstrap";
import HeaderImage from "../Images/header-video.jpg";
import HeaderImageMobile from "../Images/header-video-mobile.jpg";
import Divider from "../Images/divider.png";
import "./View/View.css";
import "./plans.css";
import Wave from "../Images/wave.png";
import { useState } from "react";
import { useEffect } from "react";
import { getAxiosInstance } from "../axios";
import { useHistory } from "react-router-dom";

function Plans() {
  const [data, setData] = useState();
  const history = useHistory();

  useEffect(() => {
    getAxiosInstance()
      .get("/plans.php")
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className="container-fluid wb-bg">
      <Row style={{ backgroundColor: "white" }}>
        <Container>
          <Row id="title-row">Wellbeing Plans</Row>
          <Row id="about-row2">About the plans</Row>
          <Row id="about-content">
            Our Balance + Glo Wellbeing Plans have been designed and curated to
            help you stay inspired and motivated, and so you can see real
            positive progress and results. We will be offering a holistic
            approach in our plans to support mental and physical wellbeing.
            Within each plan, the classes have been designed to help you get the
            most from the plan as a whole. We really recommend doing the classes
            sequentially starting from day one. You can do a few classes in one
            day or spread them over a number of days. And remember you can
            always come back to your favourite classes if you find one that
            really connects with you.
          </Row>
          <Row id="divider-row">
            <img alt="divider" src={Divider} />
          </Row>
        </Container>
      </Row>
      <Row style={{ backgroundColor: "white" }}>
        <img id="bgimg" src={Wave} />
      </Row>
      <Row style={{ position: "relative", top: -120 }}>
        {data?.map(function (object, i) {
          return (
            <div
              className="col-12 col-md-6 col-lg-3 pb-4"
              key={i}
              onClick={() => history.push("/plans/" + object.idplans)}
            >
              <div
                className="container shadow-lg plan-container"
                style={{ cursor: "pointer" }}
              >
                <div className="row">
                  <img className="plan-image" src={object.image} />
                </div>
                <div
                  className="row"
                  style={{
                    backgroundColor: "#837A85",
                    borderRadius: "0px 0px 20px 20px",
                    flexGrow: 1,
                  }}
                >
                  <div className="col pt-2">
                    <div className="container" style={{}}>
                      <div
                        className="row"
                        style={{ color: "white", fontWeight: 700 }}
                      >
                        {object.title}
                      </div>
                      <div
                        className="row pb-3 pt-2"
                        style={{
                          color: "white",
                          fontWeight: 400,
                          fontSize: "13px",
                        }}
                      >
                        {object.description}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Row>
    </div>
  );
}
export default Plans;
