import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { getAxiosInstance } from "../axios";
import VideoCard from "./VideoCard/VideoCard";
import "./VideoPanelNetflix.css";
import Pagination from "./Pagination/PaginationBlock";
import Cookies from "js-cookie";
import ArrowIcon from "../Images/arrowIcon.svg";
import ArrowIconLeft from "../Images/arrowleft.svg";
import { useHistory } from "react-router-dom";

const VideoPanelNetflix = forwardRef((props, ref) => {
  const history = useHistory();
  const [vidData, setVidData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedBox, setSelectedBox] = useState(1);
  const [loading, setLoading] = useState(true);
  const [arrowLeft, setArrowLeft] = useState(false);
  const [arrowRight, setArrowRight] = useState(true);

  useEffect(() => {
    window.addEventListener("reload-vids", (event) => {
      if (event) {
         history.push("/");

        getVideoData();
      }
    });
  }, []);

  useImperativeHandle(ref, () => ({
    getAlert(e, search) {
      setSelectedBox(1);
      getVideoData(undefined, search);
    },
    getAlert2(e, search) {
      setSelectedBox(1);
      getVideoData(undefined, undefined, search);
    },
  }));

  const scroller = (position) => {
    const scrollableWidth =
      position.target.scrollWidth - position.target.clientWidth;

    if (position.target.scrollLeft > scrollableWidth - 1) {
      setArrowRight(false);
    } else {
      setArrowRight(true);
    }

    if (position.target.scrollLeft > 0) {
      setArrowLeft(true);
    } else {
      setArrowLeft(false);
    }
  };

  useEffect(() => {
    let searchURL = window.location.search;
    let params = new URLSearchParams(searchURL);
    let searchTerm = params.get("tag");

    if (searchTerm !== null) {
      var filterArray = new Array();
      filterArray = { search: searchTerm };
      console.log(JSON.stringify(filterArray));
      localStorage.setItem("filters", JSON.stringify(filterArray));
    } else {
      //localStorage.removeItem("filters");
    }

    getVideoData();
  }, []);

  const getVideoData = (e, search, category) => {
    var cp = e;
    if (cp === undefined) {
      cp = 0;
    }
    var filters = localStorage.getItem("filters");
    if (search !== undefined && search !== null) {
      var filters = { search: search };
    }
    if (category !== undefined && category !== null) {
      var filters = { categories: category };
      localStorage.setItem("filters", filters);
    }

    var EP = "";

    if (filters != null) {
      EP = "/getvideos_filtered_v2.php?uid=";
    } else {
      EP = "/getvideos_v2.php?uid=";
    }

    setVidData();
    setLoading(true);

    console.log(localStorage.getItem("userID"));
    getAxiosInstance()
      .post(EP + localStorage.getItem("userID") + "&page=" + cp, filters)
      .then((response) => {
        console.log(response);
        setVidData(response.data);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="container-fluid">
      {vidData != undefined &&
        vidData.length > 0 &&
        localStorage.getItem("filters") === null &&
        vidData.map((value) => {
          return (
            <div className="row dragscroll">
              <div className="row cat-title align-items-baseline">
                {value.category_title}{" "}
                <div
                  className=" see-all-title"
                  onClick={() => props.reload(undefined, "Live Class Catch Up")}
                >
                  {value.category_title === "Live Class Catch Up" && (
                    <u>(See All)</u>
                  )}
                </div>
              </div>

              <div className="video-row example" onScroll={(e) => scroller(e)}>
                <div className="d-flex">
                  {arrowLeft && (
                    <div
                      class="d-flex align-items-center"
                      style={{ height: "100%", zIndex: "50" }}
                    >
                      <img
                        style={{
                          height: "30px",
                          left: "15px",
                          position: "absolute",
                        }}
                        src={ArrowIconLeft}
                      />
                    </div>
                  )}
                  {value.videos.map((value) => {
                    return (
                      <div
                        className="video-card"
                        style={{ paddingBottom: "20px", marginRight: "30px" }}
                      >
                        <VideoCard data={value} />
                      </div>
                    );
                  })}
                  {arrowRight && (
                    <div
                      class="d-flex align-items-center"
                      style={{ height: "100%", zIndex: "50" }}
                    >
                      <img
                        style={{
                          height: "30px",
                          right: "15px",
                          position: "absolute",
                        }}
                        src={ArrowIcon}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      {loading === true && (
        <div
          style={{
            height: "300px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner animation="border" style={{ color: "#787788" }} />
        </div>
      )}
      <Row>
        {vidData &&
          vidData.data &&
          vidData.data.length > 0 &&
          localStorage.getItem("filters") != null &&
          vidData.data.map((value) => {
            return (
              <Col
                xs={12}
                md={6}
                lg={4}
                style={{ paddingBottom: "20px", paddingTop: "30px" }}
              >
                <VideoCard data={value} />
              </Col>
            );
          })}
      </Row>
      {vidData && localStorage.getItem("filters") != null && (
        <Row style={{ paddingBottom: "30px" }}>
          <Pagination
            setSelectedBox={(e) => setSelectedBox(e)}
            selectedBox={selectedBox}
            currentPage={currentPage}
            setPage={(e) => (getVideoData(e), setCurrentPage(e))}
            totalPages={vidData.total_pages}
          />
        </Row>
      )}
      {vidData && vidData.length === 0 && (
        <Row
          style={{
            height: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Montserrat",
            fontSize: "18px",
            color: "#616160",
          }}
        >
          Oops! Looks like there are no results.
        </Row>
      )}
    </div>
  );
});
export default VideoPanelNetflix;
