import React, { useEffect, useState } from "react";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import { Container, Row } from "react-bootstrap";
import { Switch, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import View from "./Pages/View/View";
import Menu from "./Components/Menu/Menu";
import Plans from "./Pages/Plans";
import "./App.css";

function App() {
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    //test;

    const handleMemberInfo = ({ detail }) => {
      const { memberInfo } = detail;
      localStorage.setItem("userID", memberInfo.id);
      console.log(memberInfo);
    };
    document.addEventListener("MemberSpace.member.info", handleMemberInfo);

    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.innerHTML =
      'var MemberSpace = window.MemberSpace || {"subdomain":"balanceandgloonline"};(function(d){var s = d.createElement("script");s.src = "https://cdn.memberspace.com/scripts/widgets.js";var e = d.getElementsByTagName("script")[0];e.parentNode.insertBefore(s,e);}(document));';
    document.head.appendChild(s);

    if (
      window.location.pathname === "/on-demand-library/" ||
      window.location.pathname === "/on-demand-library"
    ) {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const foo = params.get("userID");
      localStorage.setItem("userID", foo);
    }
  });

  return (
    <div style={{ position: "relative", height: "100%", width: "100%" }}>
      <div
        style={{
          position: "absolute",
          zIndex: 0,
          width: "100%",
          display: showMenu === true ? "none" : "block",
        }}
      >
        <Container fluid className="app-grid">
          <Row>
            <Header showMenu={() => setShowMenu(!showMenu)} />
          </Row>
          <Row>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/on-demand-library">
                <Home />
              </Route>
              <Route exact path="/view">
                <View />
              </Route>
              <Route exact path="*">
                <Home />
              </Route>
            </Switch>
          </Row>
          <Row>
            <Footer />
          </Row>
        </Container>
      </div>

      {showMenu === true && (
        <div
          style={{
            position: "relative",
            zIndex: 10,
            height: "100%",
            width: "100%",
          }}
        >
          <Menu closeMenu={() => setShowMenu(!showMenu)} />
        </div>
      )}
    </div>
  );
}
export default App;
