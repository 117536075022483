import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import './ExerciseDetail.css'
import Wave from '../../Images/wave.png'
import Divider from '../../Images/divider.png'
import YogaIcon from '../../Images/yogaicon.png'
import DurationIcon from '../../Images/durationicon.png'
import IntensityIcon from '../../Images/intensityicon.png'
import {ReactComponent as Arrow}  from '../../Images/arrow.svg'

function ExerciseDetail(props) {

    var vidData = props.data
    return (

        <Container fluid>
            <Row>
                <Container>
                    <Row id="title-row">{vidData && vidData.title}</Row>
                    <Row id="about-row2">About this exercise</Row>
                    <Row id="about-content">{vidData && vidData.description_long}</Row>
                    <Row id="divider-row"><img alt="divider" src={Divider} /></Row>
                    <Row id="about-row2">Further Information</Row>
                    <Row id="about-content">{vidData && vidData.requirements}</Row>
                </Container>
            </Row>
            <Row >
                <Container id="main"  fluid>
                    <Row id="row1" ><img id="bgimg" src={Wave} /></Row>
                    {props.data &&
                        <Row id="row2">
                            <Container id="details-container">
                                <Row><Col xs="auto"><img id="instructor-image" src={props.data.instructor_image} /></Col><Col id="instructor-name-col"><Container><Row>{props.data.instructor_name}</Row>
                                    <Row id="instructor-description">{props.data.instructor_description}</Row>
                                    <Row><Button id="exercise-detail-read-more-button" onClick={() => window.location.href='https://www.balanceandgloonline.com/our-instructors'}>Read More <Arrow style={{ paddingLeft: '5px'}} fill='white' height='10px' /></Button></Row>
                                    </Container></Col><Col id="levels-col" xs="auto">
                                        
                                        <Container id="levels-container">
                                            <Row><img alt="yoga" src={YogaIcon} /> Exercise</Row>
                                            <Row id="row-content">{props.data.class_name}</Row>
                                            <Row><img alt="duration" src={DurationIcon} /> Duration</Row>
                                            <Row id="row-content">{props.data.duration} mins</Row>
                                            <Row><img alt="intensity" src={IntensityIcon} /> Intensity</Row>
                                            <Row id="row-content">{props.data.intensity}</Row>
                                        </Container>
                                    </Col></Row></Container></Row>}
                </Container></Row>
        </Container>
    )
}
export default ExerciseDetail