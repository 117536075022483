import axios from "axios";

const getAxiosInstance = () => {
  axios.defaults.withCredentials = true;

  const mInstance = axios.create({
    withCredentials: true,
    baseURL: "https://my.balanceandgloonline.com/api",
    timeout: 20000,
  });

  mInstance.interceptors.response.use(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      if (error.code === "ECONNABORTED") {
        alert("Oops, Network error");
        return Promise.reject(error);
      }
      if (error.response.status === 401) {
        window.location.href = "/login";
      }
      return Promise.reject(error);
    }
  );
  return mInstance;
};

const getAxiosInstanceLogin = () => {
  axios.defaults.withCredentials = true;

  const mInstance = axios.create({
    withCredentials: true,
    baseURL: "https://my.balanceandgloonline.com/api",
    timeout: 10000,
  });

  mInstance.interceptors.response.use(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      if (error.code === "ECONNABORTED") {
        alert("Oops, Network error");
        return Promise.reject(error);
      }
      return Promise.reject(error);
    }
  );
  return mInstance;
};

export { getAxiosInstance, getAxiosInstanceLogin };
