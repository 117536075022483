import React, { useEffect, useState} from 'react'
import { Row, Container } from 'react-bootstrap'
import {ReactComponent as Arrow}  from '../../Images/arrow.svg'
import Tick from '../../Images/tickbox.svg'
import './ClassesFilter.css'

function ClassesFilter(props) {

    const [open, setOpen] = useState(false)
    const [selectedID, setSelectedID] = useState()

    useEffect(() => {
        setSelectedID(props.value)
      })
  

    return (
        <Container style={{ backgroundColor: 'white', width: 'auto', zIndex: 3, borderRadius: '10px', paddingTop: 10, paddingBottom: 10, cursor:'pointer' }}>
            <Row onClick={() => setOpen(!open)} style={{ paddingLeft: 15, paddingRight: 15, alignItems: 'center', paddingTop: 0, fontFamily: 'Montserrat', color: '#616160', fontSize: '16px', fontWeight:600 }}>{props.title} <Arrow style={{ paddingLeft: '5px' }} fill='#616160' height='10px' /> </Row>

            {open && props.data.map(function (item, i) {
                return <Row id="item-row" onClick={() => (props.selected(item.id), setTimeout(()=>{setOpen(false)}, 200))}>{item.name} {selectedID === item.id && <img id="tick-box" src={Tick}/>}</Row>
            })
            }

        </Container>
    )
}
export default ClassesFilter