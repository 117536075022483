import React from 'react'
import './Footer.css'
import LogoWhite from '../../Images/white-logo.png'
import InstaLogo from '../../Images/insta-logo.png'
import FbLogo from '../../Images/fblogo.png'
import { Container, Row, Col } from 'react-bootstrap'

function Footer() {
    return (
        <Container fluid className="main-footer">
            <Row>
                <Col xs={12} md={4}>
                    <Container id="logo-container" fluid>
                        <Row><img id="main-logo" src={LogoWhite} alt="logo"/></Row>
                        <Row className="d-none d-md-block" id="social-row"><img onClick={() => window.location.href='https://www.instagram.com/Balanceandglo/'} src={InstaLogo} alt="logo"/><img onClick={() => window.location.href='https://www.facebook.com/balanceandglo'} src={FbLogo} alt="logo"/></Row>
                    </Container>
                </Col>

                <Col xs={12} md={5}>
                    <Container fluid className="footer-links">
                        <Row><Col onClick={() => window.location.href='https://www.balanceandgloonline.com'}>Sign Up</Col><Col onClick={() => window.location.href='https://www.balanceandgloonline.com/our-instructors'}>Our Instructors</Col></Row>
                        <Row><Col onClick={() => window.location.href='https://www.balanceandgloonline.com/members-area'}>Members Area</Col><Col onClick={() => window.location.href='https://www.balanceandgloonline.com/our-instructors'}>The Balanced Life Blog</Col></Row>
                        <Row><Col onClick={() => window.location.href='https://www.balanceandgloonline.com/classes'}>Classes</Col><Col onClick={() => window.location.href='https://www.balanceandgloonline.com/about-us'}>About Us</Col></Row>
                        <Row><Col onClick={() => window.location.href='https://www.balanceandgloonline.com/workplace-wellness'}>Workplace Wellness</Col></Row>
                    </Container>
                </Col>

                <Col xs={12} md={3}>
                    <Container fluid className="footer-links-2">
                        <Row className="d-block d-md-none" id="social-row"><img onClick={() => window.location.href='https://www.instagram.com/Balanceandglo/'} src={InstaLogo} alt="logo"/><img onClick={() => window.location.href='https://www.facebook.com/balanceandglo'} src={FbLogo} alt="logo"/></Row>
                        <Row><Col>Terms & Conditions</Col></Row>
                        <Row><Col>Privacy Policy</Col></Row>
                        <Row><Col>Cookie Policy</Col></Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    )
}
export default Footer