import React, { useEffect, useState} from 'react'
import { Row, Container } from 'react-bootstrap'
import {ReactComponent as Arrow}  from '../../Images/arrow.svg'
import Tick from '../../Images/tickbox.svg'
import './ClassesFilter.css'

function DurationsFilter(props) {

    const [open, setOpen] = useState(false)
    const [selectedID, setSelectedID] = useState()
    var data = [{"name":"Show All","value":""},{"name":"< 20 mins","value":"< 20"},{"name":"30 mins","value":"BETWEEN 20 and 30"},{"name":"60 mins","value":"BETWEEN 30 and 60"}]
    useEffect(() => {
        setSelectedID(props.value)
      })
  

    return (
        <Container style={{ backgroundColor: 'white', width: 'auto', zIndex: 5, borderRadius: '10px', paddingTop: 10, paddingBottom: 10, cursor:'pointer' }}>
            <Row onClick={() => setOpen(!open)} style={{ paddingLeft: 15, paddingRight: 15, alignItems: 'center', paddingTop: 0, fontFamily: 'Montserrat', color: '#616160', fontSize: '16px',fontWeight:600}}>Durations <Arrow style={{ paddingLeft: '5px' }} fill='#616160' height='10px' /> </Row>

            {open && data.map(function (item, i) {
                return <Row id="item-row" onClick={() => (props.selected(item.value), setTimeout(()=>{setOpen(false)}, 200))}>{item.name} {selectedID === item.value && <img id="tick-box" src={Tick}/>}</Row>
            })
            }

        </Container>
    )
}
export default DurationsFilter