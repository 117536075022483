import React,{useEffect, useState} from 'react'
import { Container, Row } from 'react-bootstrap'
import './VideoCard.css'
import Dots from '../Dots/Dots'
import ComingSoon from '../../Images/ComingSoon.jpg'
import { useHistory } from "react-router-dom";
import PlayIcon from '../../Images/Play.png'
import Shadow from '../../Images/shadowoverlay.png'
import Heart from '../../Images/heart.png'
import Watched from '../../Images/watched.png'


function VideoCard(props) {

    var url = props.data.uri.replace("videos", "video").toString()
    let history = useHistory();
    const [hovering, setHovering] = useState(false)

    useEffect(() =>{
        
    })

    return (
        <Container style={{height:'100%'}} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)} onClick={(() => history.push('/view?id=' + props.data.id))}>

            <Row id="video-row" >
                
            
                {props.data.thumbnail_url ? 
                <div className="img-hover-zoom"><div className="video-row-1"><img className="video-card-yoga-image" src={props.data.thumbnail_url}/></div><div className="video-row-2">{hovering ? <div className="overlay-div-2"> <img src={Shadow}/></div> : <div className="overlay-div"> <img src={Shadow}/></div>}<div className="video-row-icons"><div className="icon-div"><div>{props.data.watched == "true" && <img src={Watched}/>}</div><div>{props.data.favourite == "true" && <img src={Heart}/>}</div></div></div></div>
                <div className="video-row-3"><div className="container">
                    <div className="row card-text-div title-text-card">{props.data.title}</div>
                   
                    {hovering  && <div className="row" style={{ display: 'flex', alignItems: 'center', paddingLeft: '10px', paddingRight: '10px' }}><Dots number={props.data.intensity_dots} /><div style={{ fontFamily: 'Montserrat', fontWeight: '600', color: 'white', fontSize: '14px', marginLeft:'5px'}}>{props.data.intensity_level} | {props.data.class_type} | {props.data.instructor_name} | {props.data.duration} Mins</div></div>}
                    {hovering && <div className="row" style={{ display: 'flex', alignItems: 'top', paddingLeft: '10px', paddingRight: '10px', paddingTop: '10px', paddingBottom: '15px' }}><font style={{ fontFamily: 'PT Serif', fontSize: '14px', color: 'white' }}>{props.data.description}</font></div>}
                
                </div></div></div>
                 : 
                 <div className="img-hover-zoom"><img className="video-card-yoga-image" src={ComingSoon}/></div>}
               
                </Row>
           

            
        </Container>
    )
}
export default VideoCard