import React, { useEffect, useRef } from "react";
import { Container, Row } from "react-bootstrap";
import Filters from "../../Components/Filters/Filters";
import HeaderImage from "../../Images/header-video.jpg";
import HeaderImageMobile from "../../Images/header-video-mobile.jpg";
import VideoPanel from "../../Components/VideoPanel";
import VideoPanelNetflix from "../../Components/VideoPanelNetflix";
import Categories from "../../Components/Category/Categories";
import "./Home.css";
import Plans from "../Plans";
import { Switch, Route } from "react-router-dom";
import ViewPlan from "../ViewPlan";
import { useHistory, useLocation } from "react-router-dom";

function Home(props) {
  const VideoPanelNetflixRef = useRef(null);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    console.log("path:");
    console.log(location.pathname);
    if (location.pathname) {
      var pn = location.pathname.replace("/", "");
      setFilter(pn);
    }
  }, []);

  function setFilter(searchTerm) {
    var filterArray = new Array();
    filterArray = { search: searchTerm };
    console.log(JSON.stringify(filterArray));
    localStorage.setItem("filters", JSON.stringify(filterArray));
    window.history.replaceState(null, "JavaScript", "/");
  }

  window.addEventListener("reload-vids", (event) => {
    if (event) {
      history.push("/");
    }
  });

  return (
    <Container fluid>
      <Row>
        <div className="main">
          <div className="d-none d-md-flex">
            <img className="fadeImage" src={HeaderImage} alt="header" />
          </div>
          <div className="d-flex d-md-none">
            <img src={HeaderImageMobile} alt="header" />
          </div>
          <div className="second">
            <Container>
              <Row className="title-font">Wellness on Demand</Row>
              <Row className="subtitle-font">
                Classes and workshops to nourish mind, body and soul. Anytime.
                Anywhere.
              </Row>
            </Container>
          </div>
        </div>
      </Row>
      <Row>
        <Filters
          reload={(e, search) =>
            VideoPanelNetflixRef.current.getAlert(e, search)
          }
        />
      </Row>

      <Switch>
        <Route exact path="/plans">
          <Row>
            <Categories
              selected={0}
              reload={(e, search) => setFilter(search)}
            />
          </Row>
          <Row>
            <Plans />
          </Row>
        </Route>

        <Route exact path="/plans/*">
          <Row>
            <Categories reload={(e, search) => setFilter(search)} />
          </Row>
          <Row>
            <ViewPlan />
          </Row>
        </Route>

        <Route exact path="*">
          <Row>
            <Categories
              reload={(e, search) =>
                VideoPanelNetflixRef.current.getAlert2(e, search)
              }
            />
          </Row>
          <Row>
            <VideoPanelNetflix
              ref={VideoPanelNetflixRef}
              reload={(e, search) =>
                VideoPanelNetflixRef.current.getAlert2(e, search)
              }
            />
          </Row>
        </Route>
      </Switch>
    </Container>
  );
}
export default Home;
