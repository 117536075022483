import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import "./Categories.css";
import ArrowIcon from "../../Images/arrowIcon.svg";
import ArrowIconLeft from "../../Images/arrowleft.svg";
import { useHistory } from "react-router-dom";

function Categories(props) {
  const [selected, setSelected] = useState();
  const [arrowLeft, setArrowLeft] = useState(false);
  const [arrowRight, setArrowRight] = useState(true);
  const [bgColor, setBgColor] = useState();
  const history = useHistory();

  useEffect(() => {
    if (
      window.location.pathname === "/on-demand-library/plans" ||
      window.location.pathname === "/plans" ||
      window.location.pathname.includes("/plans/")
    ) {
      setBgColor("white");
    } else {
      setBgColor();
    }
  });

  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  var categoryArray = [
    {
      title: "Wellbeing Plans",
      image:
        "https://balanceandglo.s3.eu-west-2.amazonaws.com/catergories/0C2A9282-min.jpg",
      overlay_color: "#DCA591",
    },
    {
      title: "Support the Menopause",
      image:
        "https://balanceandglo.s3.eu-west-2.amazonaws.com/catergories/get-a-workout.png",
      overlay_color: "#787788",
    },
    ,
    {
      title: "Barre",
      image:
        "https://balanceandglo.s3.eu-west-2.amazonaws.com/catergories/barrecat.png",
      overlay_color: "#787788",
    },
    {
      title: "Bump + Beyond",
      image:
        "https://balanceandglo.s3.eu-west-2.amazonaws.com/catergories/Bump+and+beyond.jpg",
      overlay_color: "#9A7E83",
    },
    {
      title: "Choose Joy",
      image:
        "https://balanceandglo.s3.eu-west-2.amazonaws.com/catergories/jan.png",
      overlay_color: "#DCA591",
    },
    {
      title: "Beginners Yoga",
      image:
        "https://balanceandglo.s3.eu-west-2.amazonaws.com/catergories/shortontime.png",
      overlay_color: "#787788",
    },
    {
      title: "Intermediate Yoga",
      image:
        "https://balanceandglo.s3.eu-west-2.amazonaws.com/catergories/intermediate-yoga.png",
      overlay_color: "#DCA591",
    },
    {
      title: "Get a Workout",
      image:
        "https://balanceandglo.s3.eu-west-2.amazonaws.com/catergories/get-a-workout.png",
      overlay_color: "#787788",
    },
    {
      title: "Pilates Foundations",
      image:
        "https://balanceandglo.s3.eu-west-2.amazonaws.com/catergories/pilates-foundations.png",
      overlay_color: "#637D94",
    },
    {
      title: "Deep Sleep Series",
      image:
        "https://balanceandglo.s3.eu-west-2.amazonaws.com/catergories/GRACE-liv.jpeg",
      overlay_color: "#DCA591",
    },
    {
      title: "Short on Time",
      image:
        "https://balanceandglo.s3.eu-west-2.amazonaws.com/catergories/12days.jpg",
      overlay_color: "#9A7E83",
    },
    {
      title: "For Runners",
      image:
        "https://balanceandglo.s3.eu-west-2.amazonaws.com/catergories/movetog.png",
      overlay_color: "#9A7E83",
    },
    {
      title: "Strength and Conditioning",
      image:
        "https://balanceandglo.s3.eu-west-2.amazonaws.com/catergories/strength.png",
      overlay_color: "#DCA591",
    },
    {
      title: "Meditation",
      image:
        "https://balanceandglo.s3.eu-west-2.amazonaws.com/catergories/Explore+pilates.jpg",
      overlay_color: "#637D94",
    },
    {
      title: "Breathwork",
      image:
        "https://balanceandglo.s3.eu-west-2.amazonaws.com/catergories/Build+Strength.jpg",
      overlay_color: "#EDE8E5",
    },
    {
      title: "12 Days of Wellness",
      image:
        "https://balanceandglo.s3.eu-west-2.amazonaws.com/catergories/12days.png",
      overlay_color: "#787788",
    },
    {
      title: "The Self Reset Challenge",
      image:
        "https://balanceandglo.s3.eu-west-2.amazonaws.com/catergories/selfreset.png",
      overlay_color: "#637D94",
    },

    {
      title: "Workshops",
      image:
        "https://balanceandglo.s3.eu-west-2.amazonaws.com/catergories/shortontime.png",
      overlay_color: "#787788",
    },
  ];

  const applyFilters = (categoryText) => {
    let event = new Event("reload-vids", { bubbles: true });
    if (categoryText === "Wellbeing Plans") {
      history.push("/plans");
      return;
    }
    var filterArray = new Array();
    filterArray = { search: categoryText };
    console.log(JSON.stringify(filterArray));
    localStorage.setItem("filters", JSON.stringify(filterArray));
    // props.reload();
    window.dispatchEvent(event);
    //props.reload(undefined, categoryText);
  };

  const scroller = (position) => {
    const scrollableWidth =
      position.target.scrollWidth - position.target.clientWidth;

    if (position.target.scrollLeft > scrollableWidth - 1) {
      setArrowRight(false);
    } else {
      setArrowRight(true);
    }

    if (position.target.scrollLeft > 0) {
      setArrowLeft(true);
    } else {
      setArrowLeft(false);
    }
  };

  let squares = categoryArray.map((item, i) => (
    <div id="col" onClick={() => (applyFilters(item.title), setSelected(i))}>
      <Container id="container">
        <div id="outer">
          <div id="image">
            <img id="img" src={item.image} />
          </div>
          <div
            id="overlay-tran"
            style={{ backgroundColor: item.overlay_color }}
          ></div>
          <div id="text">{item.title}</div>
        </div>
        <div id="selected-div">
          {selected === i && <div id="selected-line" />}
        </div>
      </Container>
    </div>
  ));

  return (
    <Container fluid style={{ backgroundColor: bgColor }}>
      <div
        style={{
          position: "relative",
          paddingTop: "20px",
        }}
      >
        <div>
          <Row id="row" onScroll={(e) => scroller(e)} className="dragscroll">
            {squares}
          </Row>
        </div>
        {arrowRight && (
          <div id="arrow-div">
            <img
              style={{ height: "20px", right: "15px", position: "absolute" }}
              src={ArrowIcon}
            />
          </div>
        )}
        {arrowLeft && (
          <div id="arrow-div-left">
            <img
              style={{ height: "20px", left: "15px", position: "absolute" }}
              src={ArrowIconLeft}
            />
          </div>
        )}
      </div>
    </Container>
  );
}
export default Categories;
