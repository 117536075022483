import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import SliderImage from "../../Images/sliderbg.png";
import "./MobileFilters.css";

function MobileFilters(props) {
  var data = [
    { name: "Show All", value: "" },
    { name: "< 20 mins", value: "< 20" },
    { name: "30 mins", value: "BETWEEN 20 and 30" },
    { name: "60 mins", value: "BETWEEN 30 and 60" },
  ];

  return (
    <Container style={{ backgroundColor: "#F5F5F5", paddingTop: "10px" }}>
      <Row
        style={{
          fontFamily: "Montserrat",
          color: "#616160",
          paddingLeft: "20px",
        }}
      >
        Class Type
      </Row>
      <Row>
        <Container>
          <Row style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            {props.data.classes.map(function (item, i) {
              return (
                <Col
                  onClick={() => props.selectedClass(item.id)}
                  xs="auto"
                  style={{
                    border: "2px solid #967F83",
                    borderRadius: "8px",
                    marginRight: "10px",
                    marginTop: "10px",
                    backgroundColor:
                      props.valueClass === item.id ? "#9A7E83" : "transparent",
                  }}
                >
                  <div
                    style={{
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      fontFamily: "PT Serif",
                      fontSize: "16px",
                      color: props.valueClass === item.id ? "white" : "#616160",
                    }}
                  >
                    {item.name}
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </Row>

      <Row
        style={{
          fontFamily: "Montserrat",
          color: "#616160",
          paddingLeft: "20px",
          paddingTop: "10px",
        }}
      >
        Instructors
      </Row>
      <Row>
        <Container>
          <Row style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            {props.data.instructors.map(function (item, i) {
              return (
                <Col
                  onClick={() => props.selectedInstructor(item.id)}
                  xs="auto"
                  style={{
                    border: "2px solid #967F83",
                    borderRadius: "8px",
                    marginRight: "10px",
                    marginTop: "10px",
                    backgroundColor:
                      props.valueInstructor === item.id
                        ? "#9A7E83"
                        : "transparent",
                  }}
                >
                  <div
                    style={{
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      fontFamily: "PT Serif",
                      fontSize: "16px",
                      color:
                        props.valueInstructor === item.id ? "white" : "#616160",
                    }}
                  >
                    {item.name}
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </Row>

      <Row
        style={{
          fontFamily: "Montserrat",
          color: "#616160",
          paddingLeft: "20px",
          paddingTop: "10px",
        }}
      >
        Duration
      </Row>
      <Row>
        <Container>
          <Row style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            {data.map(function (item, i) {
              return (
                <Col
                  xs="auto"
                  onClick={() => props.selectedDuration(item.value)}
                  style={{
                    border: "2px solid #967F83",
                    borderRadius: "8px",
                    marginRight: "10px",
                    marginTop: "10px",
                    backgroundColor:
                      props.valueDuration === item.value
                        ? "#9A7E83"
                        : "transparent",
                  }}
                >
                  <div
                    style={{
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      fontFamily: "PT Serif",
                      fontSize: "16px",
                      color:
                        props.valueDuration === item.value
                          ? "white"
                          : "#616160",
                    }}
                  >
                    {item.name}
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </Row>

      <Row
        style={{
          fontFamily: "Montserrat",
          color: "#616160",
          paddingLeft: "20px",
          paddingTop: "10px",
        }}
      >
        Intensity
      </Row>

      <Row
        style={{
          position: "relative",
          zIndex: 0,
          paddingLeft: "20px",
          width: "80%",
        }}
      >
        <input
          defaultValue={props.valueIntensity}
          onChange={(e) => (
            props.selectedIntensity(e.target.value), console.log(e.target.value)
          )}
          style={{ height: "100%" }}
          type="range"
          min="0"
          max="3"
          class="slider"
          id="myRange"
        />
      </Row>

      <Row
        style={{
          paddingTop: "0px",
          width: "80%",
          fontFamily: "PT Serif",
          color: "#616160",
        }}
      >
        <Col>All</Col>
        <Col>Low</Col>
        <Col style={{ display: "flex", justifyContent: "center" }}>Medium</Col>
        <Col
          style={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
        >
          High
        </Col>
      </Row>

      <Row id="line">
        <hr />
      </Row>
      <Row style={{ paddingBottom: "20px" }}>
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => props.apply()}
            style={{ width: "90%", backgroundColor: "#9A7E83", border: "none" }}
          >
            Apply Filters
          </Button>
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => props.clear()}
            style={{ width: "90%", backgroundColor: "#BEBEBE", border: "none" }}
          >
            Clear Filters
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
export default MobileFilters;
