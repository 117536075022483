import Categories from "../Components/Category/Categories";
import { Container, Row, Button } from "react-bootstrap";
import HeaderImage from "../Images/header-video.jpg";
import HeaderImageMobile from "../Images/header-video-mobile.jpg";
import Divider from "../Images/divider.png";
import "./View/View.css";
import "./plans.css";
import Wave from "../Images/wave.png";
import { useState } from "react";
import { useEffect, useRef } from "react";
import { getAxiosInstance } from "../axios";
import VideoCard from "../Components/VideoCard/VideoCard";

function ViewPlan() {
  const [data, setData] = useState();
  const [frameHeight, setFrameHeight] = useState(0);
  const iFrame = useRef(null);

  useEffect(() => {
    var lastPart = window.location.href.split("/").pop();
    getAxiosInstance()
      .get("/plan_videos.php?id=" + lastPart)
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function ResizeIframe(id) {
    alert("loaded");
    var frame = iFrame.current;
    console.log(iFrame);
    setFrameHeight(frame.contentWindow.document.body.scrollHeight);
  }
  return (
    <div className="container-fluid wb-bg">
      <Row style={{ backgroundColor: "white" }}>
        <Container>
          <Row id="title-row">{data?.plan.title}</Row>

          <Row id="about-row2">About this plan</Row>

          <Row id="about-content">{data?.plan.long_desc}</Row>
          {data?.plan.pdf && (
            <>
              <Row id="about-row2">This Plan has a Recipie!</Row>
              <Row id="about-row2" className="pb-4">
                <Button
                  id="exercise-detail-read-more-button"
                  onClick={() => (window.location.href = data?.plan.pdf)}
                >
                  View Recipie for this Plan
                </Button>
              </Row>
            </>
          )}
          <Row id="divider-row">
            <img alt="divider" src={Divider} />
          </Row>
          <Row></Row>
        </Container>
      </Row>
      <Row style={{ backgroundColor: "white" }}>
        <img id="bgimg" src={Wave} />
      </Row>
      <Row style={{ position: "relative", top: -120 }}>
        {data?.videos?.map(function (object, i) {
          return (
            <div className="col-12 col-md-6 col-lg-4 pb-4" key={i}>
              <VideoCard data={object} />
            </div>
          );
        })}
      </Row>
    </div>
  );
}
export default ViewPlan;
