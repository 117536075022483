import React from 'react'
import './Dots.css'
function Dots(props){

    return(
        <div className="dots-div">
            <span style={{backgroundColor: props.number > 0 ? '#D3AE98' :'#D8D8D8'}}/>
            <span style={{backgroundColor: props.number > 1 ? '#D3AE98' :'#D8D8D8'}}/>
            <span style={{backgroundColor: props.number > 2 ? '#D3AE98' :'#D8D8D8'}}/>
        </div>
    )
}
export default Dots