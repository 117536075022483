import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Logo from '../../Images/BG_Logo.svg'
import Close from '../../Images/close.png'
import Gradient from '../../Images/menugradient.png'
import FBWhite from '../../Images/fbwhite.png'
import InstaWhite from '../../Images/instawhite.png'

function Menu(props){
    return(
        <Container fluid style={{display:'flex',flexDirection:'column', backgroundColor:'#EDE8E5', minHeight:'100%'}}>
            <Row style={{paddingTop:'20px', paddingLeft:'5px'}}><Col><img style={{height:'40px'}} alt="logo" src={Logo}/></Col><Col style={{display:'flex', justifyContent:'flex-end', alignItems:'center'}} onClick={()=> props.closeMenu()}><img style={{height:'15px', paddingRight:'10px'}} src={Close} alt="close"/></Col></Row>
            <Row style={{paddingTop:'50px', paddingLeft:'20px',fontFamily:'Montserrat', fontSize:'22px', color:'#9A7E83', fontWeight:'600', cursor:'pointer'}} onClick={() => window.location.href='https://www.balanceandgloonline.com'}>Sign Up</Row>
            <Row style={{paddingTop:'10px', paddingLeft:'20px',fontFamily:'Montserrat', fontSize:'22px', color:'#9A7E83', fontWeight:'600', cursor:'pointer'}} onClick={() => window.location.href='https://www.balanceandgloonline.com/members-area'}>Members Area</Row>
            <Row style={{paddingTop:'10px', paddingLeft:'20px',fontFamily:'Montserrat', fontSize:'22px', color:'#9A7E83', fontWeight:'600', cursor:'pointer'}} onClick={() => window.location.href='https://www.balanceandgloonline.com/classes'}>Classes</Row>
            <Row style={{paddingTop:'10px', paddingLeft:'20px',fontFamily:'Montserrat', fontSize:'22px', color:'#9A7E83', fontWeight:'600', cursor:'pointer'}} onClick={() => window.location.href='https://www.balanceandgloonline.com/our-instructors'}>Instructors</Row>
            <Row style={{paddingTop:'10px', paddingLeft:'20px',fontFamily:'Montserrat', fontSize:'22px', color:'#9A7E83', fontWeight:'600', cursor:'pointer'}} onClick={() => window.location.href='https://www.balanceandgloonline.com/balancedlifeblog'}>Balanced Life Blog</Row>
            <Row style={{paddingTop:'10px', paddingLeft:'20px',fontFamily:'Montserrat', fontSize:'22px', color:'#9A7E83', fontWeight:'600', cursor:'pointer'}} onClick={() => window.location.href='https://www.balanceandgloonline.com/about-us'}>About Us</Row>
           <Row style={{flex:1}}></Row>
            <Row style={{paddingTop:'30px'}}>
            <Container fluid style={{backgroundImage:  "url(" + Gradient + ")", backgroundSize:'cover'}}>
                <Row style={{paddingTop:'60px', paddingLeft:'20px',fontFamily:'Montserrat', fontSize:'22px', color:'white', fontWeight:'600'}}>Contact Us</Row>
                <Row style={{paddingTop:'20px',paddingBottom:'100px', paddingLeft:'20px'}}><img onClick={() => window.location.href='https://www.instagram.com/Balanceandglo/'} src={InstaWhite} style={{height:'30px',cursor:'pointer'}} alt="insta"/><img onClick={() => window.location.href='https://www.facebook.com/balanceandglo'} src={FBWhite} style={{height:'30px',marginLeft:'20px',cursor:'pointer'}} alt="insta"/></Row>
                </Container>
            </Row>
        </Container>
    )
}
export default Menu