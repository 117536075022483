import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import MenuImage from "../../Images/menuicon.png";
import "./Header.css";
import { useHistory } from "react-router-dom";
import Logo from "../../Images/BG_Logo.svg";

function Header(props) {
  let history = useHistory();
  return (
    <Container className="header-container" fluid>
      <Row className="d-flex d-md-none">
        <Col>
          <img onClick={() => history.push("/")} alt="logo" src={Logo} />
        </Col>
        <Col
          onClick={() => props.showMenu()}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <img style={{ height: "15px" }} src={MenuImage} alt="menu" />
        </Col>
      </Row>
      <Row className="d-none d-md-flex">
        <Col xs="auto" onClick={() => history.push("/")}>
          <img alt="logo" src={Logo} />
        </Col>
        <Col>
          <Container fluid className="menu-container">
            <Row>
              <Col
                xs="auto"
                onClick={() =>
                  (window.location.href =
                    "https://balanceandglo.com/online-studio")
                }
              >
                Online Studio
              </Col>
              <Col
                xs="auto"
                onClick={() =>
                  (window.location.href = "https://balanceandglo.com/events")
                }
              >
                Events
              </Col>
              <Col
                xs="auto"
                onClick={() =>
                  (window.location.href = "https://balanceandglo.com/retreats")
                }
              >
                Retreats
              </Col>
              <Col
                xs="auto"
                onClick={() =>
                  (window.location.href =
                    "https://balanceandglo.com/corporate-wellbeing")
                }
              >
                Corporate Wellbeing
              </Col>
              <Col
                xs="auto"
                onClick={() =>
                  (window.location.href =
                    "https://balanceandglo.com/our-instructors")
                }
              >
                Meet the Team
              </Col>
              <Col
                xs="auto"
                onClick={() =>
                  (window.location.href =
                    "https://balanceandglo.com/our-island-story")
                }
              >
                Our Island Story
              </Col>
              <Col
                xs="auto"
                onClick={() =>
                  (window.location.href =
                    "https://balanceandglo.com/members-area")
                }
              >
                Members Area
              </Col>
              <Col
                xs="auto"
                onClick={() =>
                  (window.location.href = "https://balanceandglo.com/blog")
                }
              >
                Blog
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}
export default Header;
